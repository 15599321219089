import React, { useState } from "react"

import Editor from "@monaco-editor/react"
import { Box, VStack } from "@chakra-ui/layout"
import { Select } from "@chakra-ui/select"
import ReactTerminal from "react-terminal-component"

const EditorPage = props => {
  const [codeText, setCodeText] = useState("")
  const [language, setLanguage] = useState("javascript")

  function handleEditorChange(value, change) {
    setCodeText(value)
    console.log(`model value: ${value} and change: ${JSON.stringify(change)}`)
  }

  function handleLanguageChange(event) {
    if (!event) {
      return
    }
    setLanguage(event.target.value)
  }

  return (
    <Box>
      <Select onChange={handleLanguageChange}>
        <option value="javascript">JavaScript</option>
        <option value="python">Python</option>
        <option value="cpp">C++</option>
        <option value="csharp">C#</option>
        <option value="css">CSS</option>
        <option value="go">Golang</option>
        <option value="html">HTML</option>
        <option value="java">Java</option>
        <option value="mysql">MySQL</option>
        <option value="perl">Perl</option>
        <option value="php">PHP</option>
        <option value="pgsql">PostgreSQL</option>
        <option value="objective-c">Objective-C</option>
        <option value="r">R</option>
        <option value="ruby">Ruby</option>
        <option value="scala">Scala</option>
        <option value="swift">Swift</option>
        <option value="typescript">TypeScript</option>
      </Select>
      <VStack>
        <Editor
          height="70vh"
          width="100vw"
          language={language}
          value={codeText}
          onChange={handleEditorChange}
          theme="vs-dark"
        />
        <ReactTerminal
          autoFocus={false}
          theme={{
            background: "#141313",
            promptSymbolColor: "#6effe6",
            commandColor: "#fcfcfc",
            outputColor: "#fcfcfc",
            errorOutputColor: "#ff89bd",
            fontSize: "1.1rem",
            spacing: "1%",
            fontFamily: "monospace",
            width: "100vw",
            height: "30vh",
          }}
        />
      </VStack>
    </Box>
  )
}

export default EditorPage
